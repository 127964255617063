<template>
  <div>
    <el-dialog
      title="填写确认号"
      :visible.sync="dialogFormVisible"
      width="300px"
      :close-on-click-modal="false"
    >
    <div style="color:red;margin-bottom: 10px;" v-if="orderStatus==9">
      订单已超时，为避免亏损，请向客服核实订单是否已拒单
    </div>
      <el-input size="mini" placeholder="请输入确定号" v-model.trim="upData.confirmationNo"></el-input>

      <div class="diafooterbtn">
        <el-button
          @click="cleanFn"
          style="width: 84px; margin-right: 10%"
          size="small"
          type="primary"
          plain
          >取消</el-button
        >
        <el-button
          style="width: 84px"
          @click="handEditData"
          size="small"
          type="primary"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { orderAccept_api } from "../../../apis/order";
export default {
  data() {
    return {
      upData: {
        orderId: "",
        confirmationNo: "",
      },

      dialogFormVisible: false,
      orderStatus:'',
    };
  },
  methods: {
    open(id,type) {
      this.upData.orderId = id;
      this.dialogFormVisible = true;
      this.orderStatus=type
    },
    // 取消
    cleanFn() {
      this.dialogFormVisible = false;
    },
    // 提交
    async handEditData() {
      // if (this.upData.confirmationNo == "") {
      //   this.$message.warning("请填写确定号");
      // } else {
        let { code, data } = await orderAccept_api(this.upData);
        if (code == 0) {
          this.$message.success(data ? data : "提交成功");
          this.dialogFormVisible = false;
          this.$parent.seachFn()
        }
     // }
    },
  },
};
</script>

<style lang="less" scoped>
.diafooterbtn {
  margin-top: 30px;
  text-align: center;
}
</style>>

