<template>
  <div>
    <el-dialog
      title="推送确认号"
      :visible.sync="dialogFormVisible"
      width="300px"
      :close-on-click-modal="false"
    >
      <el-input size="mini" placeholder="请输入确定号" v-model.trim="upData.confirmationNo"></el-input>

      <div class="diafooterbtn">
        <el-button
          @click="cleanFn"
          style="width: 84px; margin-right: 10%"
          size="small"
          type="primary"
          plain
          >取消</el-button
        >
        <el-button
          style="width: 84px"
          @click="handEditData"
          size="small"
          type="primary"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { confirmationNo_api } from "../../../apis/order";
export default {
  data() {
    return {
      upData: {
        orderId: "",
        confirmationNo: "",
      },

      dialogFormVisible: false,
    };
  },
  methods: {
    open(id) {
      this.upData.orderId = id;
      this.dialogFormVisible = true;
    },
    // 取消
    cleanFn() {
      this.dialogFormVisible = false;
    },
    // 提交
    async handEditData() {
      
        let { code, data } = await confirmationNo_api(this.upData);
        if (code == 0) {
          this.$message.success(data ? data : "提交成功");
          this.dialogFormVisible = false;
          this.$parent.seachFn()
        }
      
    },
  },
};
</script>

<style lang="less" scoped>
.diafooterbtn {
  margin-top: 30px;
  text-align: center;
}
</style>>

