<template>
  <div>
    <el-dialog
      title="转至订单"
      :visible.sync="dialogFormVisible"
      width="450px"
      :close-on-click-modal="false"
    >
      <el-row>
        <el-col class="fontRule" :span="8">转至位置</el-col>
        <el-col :offset="2" :span="14">
          <el-select
            size="mini"
            style="width: 130px"
            @change="changeOrderType"
            v-model="upData.orderType"
          >
            <el-option
              v-for="item in orderTypeOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row style="margin-top: 20px">
        <el-col class="fontRule" :span="8">转至原因</el-col>
        <el-col :offset="2" :span="14">
          <el-select
            size="mini"
            style="width: 150px"
            :disabled="isDisabled"
            v-model="upData.type"
          >
            <el-option
              v-for="item in typeOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row style="margin-top: 20px" v-if="upData.orderType == 6">
        <el-col class="fontRule" :span="8">金额</el-col>
        <el-col :offset="2" :span="14">
          <el-input
            size="mini"
            style="width: 150px"
            v-model="upData.appealPrice"
          ></el-input>
        </el-col>
      </el-row>
      <el-row style="margin-top: 20px">
        <el-col class="fontRule" :span="8">备注</el-col>
        <el-col :offset="2" :span="14">
          <el-input
            style="width: 200px"
            type="textarea"
            :rows="8"
            v-model.trim="upData.remarks"
          ></el-input>
        </el-col>
      </el-row>

      <div class="diafooterbtn">
        <el-button
          @click="cleanFn"
          style="width: 84px; margin-right: 10%"
          size="small"
          type="primary"
          plain
          >取消</el-button
        >
        <el-button
          style="width: 84px"
          @click="handEditData"
          size="small"
          type="primary"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { goToOrderType_api } from "../../../apis/order";
export default {
  data() {
    return {
      dialogFormVisible: false,
      upData: {
        orderIds: "",
        orderType: "",
        type: "",
        remarks: "",
        appealPrice:''
      },
      isDisabled: true,
      typeOption: [],

      orderTypeOption: [
        { label: "催单", value: 1 },
        { label: "拦截订单", value: 3 },
        { label: "二次确认", value: 4 },
        { label: "客诉订单", value: 5 },
        { label: "服务申诉", value: 6 },
      ],
    };
  },
  methods: {
    open(id) {
      this.upData.orderIds = id;

      this.dialogFormVisible = true;
    },
    // 取消
    cleanFn() {
      this.dialogFormVisible = false;
    },
    // 提交
    async handEditData() {
      if (this.upData.orderType == "") {
        this.$message.warning("请选择转至位置");
      } else {
        let { code, data } = await goToOrderType_api(this.upData);
        if (code == 0) {
          this.$message.success(data ? data : "转交成功");
          this.dialogFormVisible = false;
        }
      }
    },
    changeOrderType(val) {
      // this.upData.orderType=val
      this.upData.type = "";

      if (val == 5) {
        this.isDisabled = false;
        this.typeOption = [
          { label: "到店无房", value: 1 },
          { label: "到店查无", value: 2 },
          { label: "未到店查无", value: 3 },
          { label: "确认后满房", value: 4 },
          { label: "确认后涨价", value: 5 },
          { label: "协商取消", value: 6 },
        ];
      } else if (val == 6) {
        this.isDisabled = false;
        this.typeOption = [
          { label: "到店无房", value: 1 },
          { label: "确认后满房", value: 2 },
          { label: "确认后涨价", value: 3 },
          { label: "到店查无", value: 4 },
          { label: "协商取消", value: 5 },
        ];
      } else {
        this.isDisabled = true;
        this.typeOption = [];
      }
    },
  },
};
</script>

<style lang="less" scoped>
.diafooterbtn {
  margin-top: 30px;
  text-align: center;
}
.fontRule {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  text-align: right;
}
</style>>

