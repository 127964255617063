<template>
  <div>
    <el-dialog
      title="百度渠道取消订单"
      :visible.sync="dialogFormVisible"
      width="500px"
      :close-on-click-modal="false"
    >
      <div>
        <el-row>
          <el-col :span="6">取消类型</el-col>
          <el-col :span="18">
            <el-select size="mini" v-model="upData.audit_status" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px">
          <el-col :span="6">退款比例</el-col>
          <el-col :span="18">
            <el-input
              placeholder="部分退款且比例退款时必传, 扣10%传0.1"
              size="mini"
              v-model.trim="upData.refund_ratio"
            ></el-input>
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px">
          <el-col :span="6">退款金额</el-col>
          <el-col :span="18">
            <el-input placeholder="部分退款金额" size="mini" v-model.trim="upData.refund_money"></el-input>
          </el-col>
        </el-row>
      </div>
      <div class="diafooterbtn">
        <el-button
          @click="cleanFn"
          style="width: 84px; margin-right: 10%"
          size="small"
          type="primary"
          plain
        >取消</el-button>
        <el-button style="width: 84px" @click="handEditData" size="small" type="primary">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
  
  <script>
import { agreeBaiduCancel_api } from "../../../apis/order";
export default {
  data() {
    return {
      upData: {
        orderId: "",
        audit_status: "",
        refund_money: "",
        refund_ratio: "",
      },
      options: [
        { value: 1, label: "全额退款" },
        { value: 2, label: "部分退款" },
      ],
      

      dialogFormVisible: false,
    };
  },
  methods: {
    open(id) {
      this.upData.orderId = id;
      this.dialogFormVisible = true;
    },
    // 取消
    cleanFn() {
      this.dialogFormVisible = false;
    },
    // 提交
    async handEditData() {
      let { code, data } = await agreeBaiduCancel_api(this.upData);
      if (code == 0) {
        this.$message.success(data ? data : "提交成功");
        this.dialogFormVisible = false;
        if (this.$parent.getList()) {
          this.$parent.getList();
        }
        if (this.$parent.seachFn()) {
          this.$parent.seachFn();

          
        }
      }
    },
  },
};
</script>
  
  <style lang="less" scoped>
.diafooterbtn {
  margin-top: 30px;
  text-align: center;
}
</style>
  
  