<template>
  <div>
    <el-dialog
      title="修改业绩"
      :visible.sync="dialogFormVisible"
      width="500px"
      :close-on-click-modal="false"
    >
      <el-row :gutter="20">
        <el-col :span="6" style="text-align: right; line-height: 28px">
          底价
        </el-col>
        <el-col :span="18">
          <el-input
            placeholder="请输入底价"
            size="mini"
            style="width: 200px"
            v-model.trim="upData.totalAfterTax"
          ></el-input>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top: 20px">
        <el-col :span="6" style="text-align: right; line-height: 28px">
          采购价
        </el-col>
        <el-col :span="18">
          <el-input
            placeholder="请输入采购价"
            size="mini"
            style="width: 200px"
            v-model.trim="upData.purchasePrice"
          ></el-input>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top: 20px">
        <el-col :span="6" style="text-align: right; line-height: 28px">
          房间数
        </el-col>
        <el-col :span="18">
          <el-input
            placeholder="请输入房间数"
            size="mini"
            style="width: 200px"
            v-model.trim="upData.numberOfUnits"
          ></el-input>
        </el-col>
      </el-row>

      <el-row :gutter="20" style="margin-top: 20px">
        <el-col :span="6" style="text-align: right; line-height: 28px">
          入住时间
        </el-col>
        <el-col :span="18">
          <el-date-picker
            v-model="upData.checkIn"
            size="mini"
            style="width:200px;"
            type="date"
            placeholder="选择日期"
             value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top: 20px">
        <el-col :span="6" style="text-align: right; line-height: 28px">
         离店时间
        </el-col>
        <el-col :span="18">
          <el-date-picker
            v-model="upData.checkOut"
            size="mini"
            style="width:200px;"
            type="date"
            placeholder="选择日期"
             value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-col>
      </el-row>

      <div class="diafooterbtn">
        <el-button
          @click="cleanFn"
          style="width: 84px; margin-right: 10%"
          size="small"
          type="primary"
          plain
          >取消</el-button
        >
        <el-button
          style="width: 84px"
          @click="handEditData"
          size="small"
          type="primary"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { updateProfitPrice_api } from "../../../apis/Finance";
export default {
  data() {
    return {
      upData: {
        orderId: "",
        purchasePrice: "",
        totalAfterTax: "",
        checkIn: "",
        checkOut: "",
        numberOfUnits: "",
      },

      dialogFormVisible: false,
    };
  },
  methods: {
    open(row) {
      // console.log(row)
      if(row.id){
         this.upData.orderId = row.id;
      }
      if(row.orderId){
         this.upData.orderId = row.orderId;
      }
      this.upData.totalAfterTax = row.totalAfterTax;
      this.upData.purchasePrice = row.purchasePrice;
      this.upData.checkIn = row.checkIn;
      this.upData.checkOut = row.checkOut;
      this.upData.numberOfUnits = row.numberOfUnits;
      this.dialogFormVisible = true;
    },
    // 取消
    cleanFn() {
      this.dialogFormVisible = false;
    },

    // 提交
    async handEditData() {
      if (this.upData.purchasePrice == "" || this.upData.totalAfterTax == "") {
        this.$message.warning("请填写底价和采购价");
      } else {
        let { code, data } = await updateProfitPrice_api(this.upData);
        if (code == 0) {
          this.$message.success(data ? data : "提交成功");
          this.dialogFormVisible = false;
          if(this.$parent.getList()){
             this.$parent.getList();
          }
          if(this.$parent.seachFn()){
            this.$parent.seachFn()
          }
          
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.diafooterbtn {
  margin-top: 30px;
  text-align: center;
}
.leftBox {
  display: inline-block;
  width: 140px;
  margin-right: 20px;
}
</style>

