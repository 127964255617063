<template>
    <div class="workManagement">
        <!-- 排班管理 -->
        <div class="serchbox">
            <el-form :inline="true" :model="form" class="demo-form-inline">
                <el-form-item label="日期">
                    <el-date-picker v-model="form.date" size="small" type="datetimerange" align="right" unlink-panels
                        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                        :picker-options="pickerOptions" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss">
                    </el-date-picker>
                </el-form-item>
               
                <el-form-item>
                    <el-button size="mini" type="primary" @click="searchFn">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- 排班表格 -->
        <div class="roleTable">
            <div class="head">
                <!-- 按钮选项 -->
                <div class="btn">
                   
                </div>
                <!-- 分页 -->
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="searchData.page" :page-size="searchData.rows" :page-sizes="[15, 30, 45, 60]"
                    layout="total, sizes, prev, pager, next" :total="total">
                </el-pagination>
            </div>
            <!-- 表格 -->
            <el-table :data="tableData" style="width: 100%">
                <el-table-column  prop="confirmOrder" label="确认订单数" align="center">
                </el-table-column>
                <el-table-column  prop="totalOrder" label="总订单数" align="center">
                </el-table-column>
                <el-table-column  prop="confirmRate" label="确认率" align="center">
                </el-table-column>
                <el-table-column  prop="startTime" label="开始时间" align="center">
                </el-table-column>
                <el-table-column  prop="endTime" label="结束时间" align="center">
                </el-table-column>

                <!-- <el-table-column prop="platformIds" label="渠道" align="center">
                    <template slot-scope="scope">
                        <div>
                            {{ scope.row.platformIds.join(",") }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="班型" prop="" align="center">
                    <template slot-scope="scope">
                        <el-tag>{{ scope.row.workName }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="上班状态" align="center">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.workStatus == '下班'">{{
                scope.row.workStatus
            }}</el-tag>
                        <el-tag type="success" v-if="scope.row.workStatus == '上班中'">{{
                scope.row.workStatus
            }}</el-tag>
                        <el-tag type="info" v-if="scope.row.workStatus == '休息中'">{{
                scope.row.workStatus
            }}</el-tag>
                        <el-tag type="danger" v-if="scope.row.workStatus == '未打卡'">{{
                scope.row.workStatus
                            }}</el-tag>
                    </template>
                </el-table-column> -->
               
               
            </el-table>
        </div>
        <!-- 修改排班管理弹出框 -->
       
    </div>
</template>

<script>

import {ElongkpiList_api} from "../../../apis/order"
export default {
    components: {
        
    },
    data() {
        return {
            // 选项卡按钮
            btnCur: "0",
            // 角色查询新增
            form: {
                user: "", // 客服姓名
                classType: "", // 班型
                date: "", // 日期选择
            },
            currentPage: 1, //当前页
            count: 15, //当前页条数
            total: 0, //总数
            tableData: [], //   角色表格
            managerName: "", // 客服姓名
            //选项按钮
          
            //日期选择
            pickerOptions: {
                shortcuts: [
                    {
                        text: "最近一周",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "最近一个月",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "最近三个月",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit("pick", [start, end]);
                        },
                    },
                ],
            },
            classOptions: [], // 班次选择(早中晚)
            oldList: [],
            searchData:{
                page:1,
                rows:15,
                startTime:'',
                endTime:'',
            }
        };
    },
    created() {
      
       this.searchFn()
       
    },
    methods: {
        searchFn(){
            this.searchData.page=1
            this.getElongkpiList()
        },
       
        //分页
        handleSizeChange(val) {
            this.count = val;
            this.searchData.rows=val;
            this.searchFn()
           
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.searchData.page=val
            this.getElongkpiList()
          
        },
        //
       
        getElongkpiList(){
            if(this.form.date == null) {
                this.form.date = [];
                this.form.date[0] = "";
                this.form.date[1] = "";
            }
            this.searchData.startTime=this.form.date[0]
            this.searchData.endTime=this.form.date[1]
            ElongkpiList_api(this.searchData).then((res) => {
                if (res.code == 0) {
                   
                    this.tableData = res.data.records;
                    this.total = res.data.total;
                }
            });
        },
       
       
       
      
       
    },
};
</script>

<style lang="less" scoped>
/deep/.el-input__inner,
/deep/.el-range-input {
    background: #ebeff4;
}

/deep/.has-gutter {
    background: #ebf1fa !important;
}

.workManagement {
    /deep/.demo-form-inline {
        width: 100%;
        height: 70px;
        background: #fff;
        display: flex;
        align-items: center;
        box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);

        .el-form-item {
            margin-bottom: 0;
            margin-left: 15px;
        }
    }

    .roleTable {
        width: 100%;
        // height: 550px;
        margin-top: 20px;
        background: #fff;
        display: flex;
        flex-direction: column;
        box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);

        .head {
            display: flex;
            justify-content: space-between;

            .btn {
                padding: 10px;
                box-sizing: border-box;

                button {
                    border: 1px solid #256def;
                    color: #256def;
                }

                .el-button.active {
                    background: #256def;
                    color: #fff;
                }
            }

            .el-pagination {
                padding: 10px;
                box-sizing: border-box;
            }
        }
    }
}
.serchbox{
    margin-top: 30px;
}
</style>