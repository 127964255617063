<template>
  <div>
    <el-dialog
      title="客诉回复"
      :visible.sync="dialogFormVisible"
      width="500px"
      :close-on-click-modal="false"
      @closed="cleanDataFn"
    >
      <el-form label-position="right" label-width="150px">
        <el-form-item label="订单号："> {{orderSn}} </el-form-item>
        <el-form-item label="事件类型："> {{evenType | formatEvetType}} </el-form-item>
        <el-form-item label="备注：">
             <el-input type="textarea" style="width:170px;"  :autosize="{ minRows: 6, maxRows: 20}" v-model.trim="remarks"></el-input>
        </el-form-item>
      </el-form>

      <div class="diafooterbtn">
        <el-button
          @click="cleanFn"
          style="width: 84px; margin-right: 10%"
          size="small"
          type="primary"
          plain
          >取消</el-button
        >
        <el-button
          style="width: 84px"
          @click="handEditData"
          size="small"
          type="primary"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { eventOver_api } from "../../../apis/order";
export default {
  data() {
    return {
      dialogFormVisible: false,
      orderId:'',
      orderSn:'',
      evenType:'',
      remarks:'',
    };
  },
  filters:{
        //事件类型格式化
    formatEvetType(cellValue) {
      if (cellValue == 1) {
        return "到店无房";
      } else if (cellValue == 2) {
        return "到店查无";
      } else if (cellValue == 3) {
        return "未到店查无";
      } else if (cellValue == 4) {
        return "确认后满房";
      } else if (cellValue == 5) {
        return "确认后涨价";
      } else if (cellValue == 6) {
        return "确认前满房";
      } else if (cellValue == 7) {
        return "确认前涨价";
      } else if (cellValue == 8) {
        return "协商取消";
      }
    },

  },
  methods: {
    open(orderId,orderSn,evenType) {
      this.orderId=orderId
      this.orderSn=orderSn
      this.evenType=evenType
      this.dialogFormVisible = true;
    },
    // 取消
    cleanFn() {
      this.dialogFormVisible = false;
    },
    // 提交
 async   handEditData() {
        if (this.remarks == "") {
          this.$message.warning("请填写备注");
        } else {
          let { code, data } = await eventOver_api({
              orderId:this.orderId,
              remarks:this.remarks
          });
          if (code == 0) {
            this.$message.success(data ? data : "提交成功");
            this.dialogFormVisible = false;
            this.$parent.seachFn()
          }
        }
    },
    cleanDataFn() {
      Object.assign(this.$data, this.$options.data());
    },
  },
};
</script>

<style lang="less" scoped>
.diafooterbtn {
  margin-top: 30px;
  text-align: center;
}
</style>>