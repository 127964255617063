<template>
  <div>
    <el-dialog
      title="百度渠道手动退款"
      :visible.sync="dialogFormVisible"
      width="1200px"
      :close-on-click-modal="false"
    >
      <div class="line56">
        <el-row>
          <el-col :span="6">取消类型</el-col>
          <el-col :span="18">
            <el-select size="mini" v-model="upData.audit_status" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px" v-if=" upData.audit_status == 2">
          <el-col :span="6">退款比例</el-col>
          <el-col :span="18">
            <el-input
              placeholder="退款比例，扣10%传0.1"
              size="mini"
              v-model.trim="upData.refund_data.refund_ratio"
            ></el-input>
          </el-col>
        </el-row>
        <el-row
          style="margin-top: 20px"
          v-if="upData.audit_status == 4 ||  upData.audit_status == 2"
        >
          <el-col :span="6">退款金额</el-col>
          <el-col :span="18">
            <el-input placeholder="退款金额" size="mini" v-model.trim="upData.refund_data.refund_money"></el-input>
          </el-col>
        </el-row>
        <el-row
          style="margin-top: 20px"
          v-if="upData.audit_status == 4 || upData.audit_status == 1 || upData.audit_status == 2"
        >
          <el-col :span="6">退款原因</el-col>
          <el-col :span="18">
            <el-input type="textarea" :rows="4" v-model.trim="upData.refund_data.refund_reason"></el-input>
          </el-col>
        </el-row>
        <div style="margin-top: 20px" v-if="upData.audit_status == 3">
          <el-button type="primary" @click="addPerson" size="mini">+增加入住人</el-button>

          <el-row v-for="(item,index) in refundCalendarData" :key="index" style="margin-top: 20px">
            <el-col :span="2" class="textcenter">入住人</el-col>
            <el-col :span="3">
              <el-input placeholder="入住人" size="mini" v-model.trim="item.name"></el-input>
            </el-col>
            <el-col :span="16">
              <el-row v-for="(etem,i) in item.time" :key="i" style="margin-bottom: 10px">
                <el-col :span="2" class="textcenter">入住时间</el-col>
                <el-col :span="4">
                  <el-date-picker
                    size="mini"
                    style="width: 124px;"
                    v-model="etem.time"
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-col>
                <el-col :span="2" class="textcenter">取消类型</el-col>
                <el-col :span="4">
                  <el-select size="mini" v-model="etem.cancel_type" placeholder="请选择">
                    <el-option
                      v-for="item in optionstwo"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-col>
                <el-col :span="2" class="textcenter">退款金额</el-col>
                <el-col :span="4">
                  <el-input
                    type="number"
                    placeholder="退款金额"
                    size="mini"
                    v-model="etem.refund_amount"
                  ></el-input>
                </el-col>
                <el-col :span="2" class="textcenter" v-if="etem.cancel_type==4">比例退款</el-col>
                <el-col :span="4" v-if="etem.cancel_type==4">
                  <el-input
                    type="number"
                    placeholder="退款比例，扣10%传0"
                    size="mini"
                    v-model="etem.refund_ratio"
                  ></el-input>
                </el-col>
              </el-row>
            </el-col>

            <el-col :span="3">
              <el-button type="primary" @click="addTime(item)" size="mini">+增加时间</el-button>
            </el-col>
          </el-row>
        </div>
        <div style="margin-top: 20px;"></div>
      </div>
      <!-- <el-button type="primary" @click="encryptText('测试')" size="mini">测试</el-button> -->
      <div class="diafooterbtn">
        <el-button
          @click="cleanFn"
          style="width: 84px; margin-right: 10%"
          size="small"
          type="primary"
          plain
        >取消</el-button>
        <el-button style="width: 84px" @click="handEditData" size="small" type="primary">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
    
    <script>
import { baiduRefund_api } from "../../../apis/order";
import CryptoJS from "crypto-js";
export default {
  data() {
    return {
      upData: {
        orderId: "",
        audit_status: "",
        refund_data: {
          audit_status: "",
          refund_reason: "",
          refund_money: "",
        },
        refund_calendar: "",
      },
      options: [
        { value: 1, label: "全额退款" },
        { value: 2, label: "部分退款" },
        { value: 3, label: "间夜退款" },
        { value: 4, label: "固定金额退款" },
      ],
      optionstwo: [
        { value: 1, label: "全额退款" },
        { value: 2, label: "固定金额退款" },
        { value: 3, label: "扣除取消费退款" },
        { value: 4, label: "比例退款" },
      ],

      personData: { name: "", time: "" },
      timeData: { time: "", refund_amount: "", cancel_type: "" },
      refundCalendarDatanew: {
        name: "",
        time: [
          { time: "", refund_amount: "", cancel_type: "", refund_ratio: "" },
        ],
      },
      refundCalendarData: [
        {
          name: "",
          time: [
            { time: "", refund_amount: "", cancel_type: "", refund_ratio: "" },
          ],
        },
      ],

      dialogFormVisible: false,
    };
  },
  methods: {
    open(id) {
      this.upData = {
        orderId: "",
        audit_status: "",
        refund_data: {
          audit_status: "",
          refund_reason: "",
          refund_money: "",
        },
        refund_calendar: "",
      };
      this.upData.orderId = id;
      this.dialogFormVisible = true;
    },
    // 取消
    cleanFn() {
      this.dialogFormVisible = false;
    },
    // 提交
    async handEditData() {
      if (
        this.upData.audit_status == 4 ||
        this.upData.audit_status == 1 ||
        this.upData.audit_status == 2
      ) {
        this.upData.refund_data.audit_status = this.upData.audit_status;
        this.upData.refund_calendar = "";
      } else if (this.upData.audit_status == 3) {
        this.upData.refund_data = "";
       // console.log(this.refundCalendarData);
        this.upData.refund_calendar = '';
        this.upData.refund_calendar=JSON.stringify(this.refundCalendarData);
        // this.refundCalendarData.forEach((item) => {
        //   this.upData.refund_calendar.push(item);
        // });

        //  let newStr = {};
        // this.refundCalendarData.forEach((item) => {
        //   let newStr1 = {};

        //   item.time.forEach((etem) => {
        //     etem.refund_amount = Number(etem.refund_amount);
        //     let newStr2 = {
        //       refund_amount: etem.refund_amount,
        //       cancel_type: etem.cancel_type,
        //       refund_ratio: etem.refund_ratio,
        //     };

        //     this.$set(newStr1, etem.time, newStr2);
        //   });
        //  //  let encryptName = this.encryptText(item.name);

        //   this.$set(newStr, item.name, newStr1);
        // });

        // console.log(JSON.stringify(newStr));
        // this.upData.refund_calendar = newStr;

       console.log(this.upData.refund_calendar);

      }
      let { code, data } = await baiduRefund_api(this.upData);
      if (code == 0) {
        this.$message.success(data ? data : "提交成功");
        this.dialogFormVisible = false;
        if (this.$parent.getList()) {
          this.$parent.getList();
        }
        if (this.$parent.seachFn()) {
          this.$parent.seachFn();
        }
      }
    },
    addPerson() {
      let obj = {
        name: "",
        time: [{ time: "", refund_amount: "", cancel_type: "" }],
      };
      this.refundCalendarData.push(obj);
    },
    addTime(item) {
      let obj = { time: "", refund_amount: "", cancel_type: "" };
      item.time.push(obj);
      console.log(JSON.stringify(this.refundCalendarData));
    },
    encryptText(plaintext) {
      // console.log(plaintext);
      // 密钥和初始化向量（IV）应该是随机生成的，这里为了示例简单使用固定值
      //  const key = CryptoJS.enc.Utf8.parse("f3ce22852a5b444098f31e426c7e1c91"); // 密钥
      const key = "f3ce22852a5b444098f31e426c7e1c91"; // 密钥
      //  const iv = CryptoJS.enc.Utf8.parse(""); // 初始化向量
      const iv = "1"; // 初始化向量

      // 使用AES加密
      const encrypted = CryptoJS.AES.encrypt(plaintext, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
      // console.log(encrypted.toString())
      return encrypted.toString();
    },
  },
};
</script>
    
    <style lang="less" scoped>
.diafooterbtn {
  margin-top: 30px;
  text-align: center;
}
.line56 {
  line-height: 28px;
}
.textcenter {
  text-align: center;
}
</style>
    
    