import req from "../utils/req"

//获取转款模块所有订单
export function getTransferOrderList(params) {
    return req.get("/otaHotel/finance/transfer/getTransferOrderList", params)
}
// 获取预付款列表
export function getAdvanceChargeBaseInfoList(params) {
    return req.get("/otaHotel/finance/advance/getAdvanceChargeBaseInfoList", params)
}
// 获取报表数据列表
export function getReports(params) {
    return req.get("/otaHotel/finance/report/getReports", params)
}
// 获取退款订单列表
export function getRefundOrderList(params) {
    return req.get("/otaHotel/finance/refund/getRefundOrderList", params)
}
// 获取酒店抵充款订单列表
export function getOffsetOrderList(params) {
    return req.get("/otaHotel/finance/refund/getOffsetOrderList", params)
}
// 发票审核/获取发票基础信息列表
export function getInvoiceBaseInfoList(params) {
    return req.get("/otaHotel/finance/examine/getInvoiceBaseInfoList", params)
}
// 回执审核/获取回执审核订单列表
export function getReceiptOrderList(params) {
    return req.get("/otaHotel/finance/examine/getReceiptOrderList", params)
}
// 获取支付账户列表和酒店剩余抵充款
export function getPayConfigListAndOffsetPrice(params) {
    return req.get("/otaHotel/finance/transfer/getPayConfigListAndOffsetPrice", params)
}
// 获取渠道马甲列表
export function getPlatformList(params) {
    return req.get("/otaHotel/finance/transfer/getPlatformList", params)
}
// 根据角色获取组与组员|(统计方面的)统一用这个
export function getGroups(params) {
    return req.get("/otaHotel/horseGroup/getGroups", params)
}
// 转款订单导出
export function transferOrderExportExcel(params) {
    return req.get("/otaHotel/finance/transfer/transferOrderExportExcel", params)
}
// 审核发票导出
export function invoiceExportExcel(params) {
    return req.get("/otaHotel/finance/examine/invoiceExportExcel", params)
}
// 发票审核/获取发票详细信息列表
export function getInvoiceDetailInfoList(params) {
    return req.get("/otaHotel/finance/examine/getInvoiceDetailInfoList", params)
}
// 报表导出
export function reportsExportExcel(params) {
    return req.get("/otaHotel/finance/report/reportsExportExcel", params)
}
// 预付款/获取预付款详细信息列表
export function getAdvanceChargeDetailInfoList(params) {
    return req.get("/otaHotel/finance/advance/getAdvanceChargeDetailInfoList", params)
}
// 发票审核/上传发票》》获取产品经理下的酒店列表
export function getHotelList(params) {
    return req.get("/otaHotel/finance/examine/getHotelList", params)
}
// 获取预付款酒店列表
export function getPrepaidHotel_api(params) {
    return req.get("/otaHotel/hotel-prepay-operation-records/getPrepaidHotel", params)
}
// 获取预付款酒店处理记录列表
export function getPrepaidHotelOperationRecords_api(params) {
    return req.get("/otaHotel/hotel-prepay-operation-records/getPrepaidHotelOperationRecords", params)
}
//设置预付款金额
export function setPrepayAmount_api(params) {
    return req.postlg("/otaHotel/hotel-prepay-operation-records/setPrepayAmount", params)
}
//标记提前转款
export function beforeTransfer_api(params) {
    return req.get("/otaHotel/finance/transfer/advance", params)
}
//线下转和批量转线下
export function offlineTurn_api(params) {
    return req.get("/otaHotel/finance/transfer/offlineTurn", params)
}
//获取线下转款账户列表
export function getOfflinePayerAccountList_api(params) {
    return req.get("/otaHotel/finance/transfer/getOfflinePayerAccountList", params)
}
//驳回和批量驳回
export function transferReject_api(params) {
    return req.get("/otaHotel/finance/transfer/reject", params)
}
//提交补款单
export function addBkOrder_api(params) {
    return req.get("/otaHotel/order/addBkOrder", params)
}
//获取酒店抬头
export function getHeaderList(params) {
    return req.get("/otaHotel/finance/examine/getHeaderList", params)
}
//修改业绩
export function updateProfitPrice_api(params) {
    return req.postlg("/otaHotel/finance/report/updateProfitPrice", params)
}
//设置冲抵金额
export function produceOffsetPrice_api(params) {
    return req.get("/otaHotel/finance/transfer/offsetPrice", params)
}
//预付款驳回
export function toRejectedFn_api(params) {
    return req.postlg("/otaHotel/finance/advance/reject", params)
}

//线上转款
export function advanceHotelTransfer_api(hotelSonId,transferAmount,accountId) {
    return req.postlg(`/otaHotel/finance/transfer/advanceHotelTransfer?hotelSonId=${hotelSonId}&transferAmount=${transferAmount}&accountId=${accountId}`)
}