<template>
  <div>
    <el-dialog
      title="修改订单状态"
      :visible.sync="dialogFormVisible"
      width="500px"
      :close-on-click-modal="false"
    >
      <div class="centerbox">
        请选择状态
        <el-select v-model="detailData.status" size="mini">
          <el-option
            v-for="item in stateList"
            :key="item.value"
            :value="item.value"
            :label="item.label"
          >
          </el-option>
        </el-select>
      </div>
       <div class="diafooterbtn">
        <el-button
          @click="cleanFn"
          style="width: 84px; margin-right: 10%"
          size="small"
          type="primary"
          plain
          >取消</el-button
        >
        <el-button
          style="width: 84px"
          @click="handEditData"
          size="small"
          type="primary"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { updateOrderStatus_api } from "../../../apis/order";
export default {
  data() {
    return {
      dialogFormVisible: false,
      stateList: [
       
        { label: "未处理", value: "0" },
        { label: "已确认", value: "1" },
        { label: "确认前取消中", value: "2" },
        { label: "确认后取消中", value: "3" },
        { label: "确认前取消", value: "4" },
        { label: "确认后取消", value: "5" },
        { label: "超时拒绝", value: "6" },
        { label: "正常拒绝", value: "7" },
        { label: "保留房确认中", value: "8" },
        { label: "订单超时", value: "9" },
        { label: "拒绝取消", value: "10" },
      ],
      detailData:{
          orderId:'',
          status:'',
      },
    };
  },
  methods:{
      open(row){
          console.log(row)
        this.detailData.orderId=row.orderId
        this.detailData.status=row.status
        this.dialogFormVisible=true
      },
      cleanFn(){
          this.dialogFormVisible = false;
      },
    async  handEditData(){
        let {code,data} =await updateOrderStatus_api({
            orderId:this.detailData.orderId,
            status:this.detailData.status
        })
        if(code==0){
        this.$message.success('修改成功')
         this.dialogFormVisible = false;
        this.$parent.seachFn()
        }
      }
  }
};
</script>

<style lang="less" scoped>
.diafooterbtn {
  margin-top: 30px;
  text-align: center;
}
.centerbox{
    text-align: center;
}

</style>