<template>
  <div>
    <el-dialog
      title="选择要转的人"
      :visible.sync="dialogFormVisible"
      width="500px"
      :close-on-click-modal="false"
    >
      <el-form label-position="right" label-width="150px">
        <el-form-item label="职位：">
          <el-select v-model="positionValue" size="mini" @change="getGroups">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="组别：">
          <el-select v-model="groupValue" size="mini" @change="getPersons">
            <el-option
              v-for="(item,index) in groupOptions"
              :key="item.keyy"
              :label="item.valuee"
              :value="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="人员：">
          <el-select v-model="personValue" size="mini" @change="getPersonsvale">
            <el-option
              v-for="item in personOptions"
              :key="item.keyy"
              :label="item.valuee"
              :value="item.keyy"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：">
            <el-input type="textarea" style="width:250px;" :rows="8" v-model.trim="upData.remarks"></el-input>
        </el-form-item>
      </el-form>

     

      <div class="diafooterbtn">
        <el-button
          @click="cleanFn"
          style="width: 84px; margin-right: 10%"
          size="small"
          type="primary"
          plain
          >取消</el-button
        >
        <el-button
          style="width: 84px"
          @click="handEditData"
          size="small"
          type="primary"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getGroups_api } from "../../../apis/order";
import { eventGtM_api } from "../../../apis/order";

export default {
  data() {
    return {
      upData: {
        orderId: "",
        remarks:'',
      },
      positionValue: "",
      groupValue:'',
      dialogFormVisible: false,
      options: [
        { label: "产品", value: 0 },
        { label: "运营", value: 1 },
        { label: "售前", value: 2 },
        { label: "售后", value: 3 },
        { label: "二次确认", value: 4 },
      ],
     
      groupOptions :[],
      personOptions:[],
      personValue:'',


    };
  },
  methods: {
    open(id) {
        console.log(id)
      this.upData.orderId = id;
      this.dialogFormVisible = true;
    },
    // 取消
    cleanFn() {
      this.dialogFormVisible = false;
    },
    // 提交
    async handEditData() {
      if (this.personValue == "") {
        this.$message.warning("请选择需要转至的人");
      } else {
          this.upData.managerId=this.personValue
        let { code, data } = await eventGtM_api(this.upData);
        if (code == 0) {
          this.$message.success(data ? data : "提交成功");
          this.dialogFormVisible = false;
        }
      }
    },
    //获取组别
    async getGroups(val) {
      let { code, data } = await getGroups_api({
        positionType: val,
      });
      if (code == 0) {
          this.groupValue=''
          this.personValue=''
          this.personOptions=[]
          this.groupOptions=data
      }
    },
    //获取人的信息
    getPersons(val){
        console.log(val)
        this.personValue=''
        this.personOptions=this.groupOptions[val].list

    },
    getPersonsvale(val){
        console.log(val)
    }
  },
};
</script>

<style lang="less" scoped>
.diafooterbtn {
  margin-top: 30px;
  text-align: center;
}
</style>>
