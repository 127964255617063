<template>
  <div>
    <el-dialog
      title="订单信息"
      :visible.sync="dialogFormVisible"
      width="500px"
      :close-on-click-modal="false"
    >
      <div class="orderInfoBox">
        <el-row class="marginbottom20">
          <el-col style="text-align: right" :span="8">订单号:</el-col>
          <el-col :offset="1" :span="14">{{ defaultData.orderSn }}</el-col>
        </el-row>
        <el-row class="marginbottom20">
          <el-col style="text-align: right" :span="8">城市/酒店名称:</el-col>
          <el-col :offset="1" :span="14">{{ defaultData.orderAddress }}</el-col>
        </el-row>
        <el-row class="marginbottom20">
          <el-col style="text-align: right" :span="8">房型:</el-col>
          <el-col :offset="1" :span="14"
            >{{ defaultData.roomTypeAndSum }}{{ defaultData.fewRoom }}</el-col
          >
        </el-row>
        <el-row class="marginbottom20">
          <el-col style="text-align: right" :span="8">入离时间:</el-col>
          <el-col :offset="1" :span="14"
            >{{ defaultData.inAndOutTime }}{{ defaultData.fewNights }}</el-col
          >
        </el-row>
        <el-row class="marginbottom20">
          <el-col style="text-align: right" :span="8">入住人:</el-col>
          <el-col :offset="1" :span="14"
            >{{ defaultData.inManAndSum }}{{ defaultData.fewMan }}</el-col
          >
        </el-row>
      </div>

      <div class="diafooterbtn">
        <el-button
          @click="cleanFn"
          style="width: 84px; margin-right: 10%"
          size="small"
          type="primary"
          plain
          >取消</el-button
        >
        <el-button
          style="width: 84px"
          @click="handEditData"
          size="small"
          type="primary"
          >拷贝</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      defaultData: "",

      dialogFormVisible: false,
    };
  },
  methods: {
    open(data) {
      this.defaultData = data;

      this.dialogFormVisible = true;
    },
    // 取消
    cleanFn() {
      this.dialogFormVisible = false;
    },
    // 提交
    handEditData() {
      let that = this;
      this.$copyText(
        `订单号：${that.defaultData.orderSn}\n 城市/酒店名称：${that.defaultData.orderAddress}\n 房型：${that.defaultData.roomTypeAndSum}${that.defaultData.fewRoom}\n 入离时间：${that.defaultData.inAndOutTime}${that.defaultData.fewNights}\n 入住人：${that.defaultData.inManAndSum}${that.defaultData.fewMan}`
      ).then((e) => {
        this.$message({
          type: "success",
          message:
            "成功复制订单" +
            `订单号：${that.defaultData.orderSn}\n 城市/酒店名称：${that.defaultData.orderAddress}\n 房型：${that.defaultData.roomTypeAndSum}${that.defaultData.fewRoom}\n 入离时间：${that.defaultData.inAndOutTime}${that.defaultData.fewNights}\n 入住人：${that.defaultData.inManAndSum}${that.defaultData.fewMan}`,
        });
        this.dialogFormVisible = false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.diafooterbtn {
  margin-top: 30px;
  text-align: center;
}
.orderInfoBox {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.marginbottom20 {
  margin-bottom: 20px;
}
</style>>

